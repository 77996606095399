<template>
  <div class="mb-129">
    <h1 class="text-39 text-grey-900 font-bbva-book collective-body-title">{{ option.title }}</h1>
    <h3 class="text-24 font-semibold mb-24">{{ option.question }}</h3>
    <div class="pl-21">
      <p class="text-22 text-grey-900 mb-24 font-bbva-book font-medium">{{ option.answer }}</p>
      <div class="collective-body-list-wrapper text-grey-500 pl-20" v-html="option.list" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollectivesAndContributionsBody',
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.collective-body-title {
  border-bottom: 1px solid theme('colors.grey.250');
  margin-bottom: 56px;
}

.collective-body-list-wrapper ul li::before {
  display: inline-block;
  width: 1em;
  color: theme('colors.blue.500');
  content: '\2022';
  font-size: 20px;
  font-weight: bold;
}
</style>
